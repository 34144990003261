import React from "react";
import AdminLayout from "../../../../components/admin/adminLayout";
import PenaltyUnitsJurisdictionsComponent from "../../../../components/admin/penaltyUnitsJurisdictionsComponent";
import Apollo from "../../../../components/apollo";
import { penaltyUnitCalculatorQuery } from "../../../../utils/staticQueryFragments";
import SEO from "../../../../components/seo";
import WithPrivateRoute from "../../../../components/admin/withPrivateRoute";
import PenaltyUnitsJurisdictionsSkeleton from "../../../../components/admin/penaltyUnitsJurisdictionsSkeletonComponent";

const ToolsPenaltyUnits = (props) =>
{
  return (
    <AdminLayout>
      <SEO
        keywords = { [`LawChest`, `admin`, `tools`, `administration`, `penalty`, `unit`, `calculator`] }
        title = "Penalty Unit Calculator"
        isAdmin = { true }
      />
      <Apollo
        propsToInsert =
        {
          (queryResults) => (
            { jurisdictionsQueryResults: queryResults }
          )
        }
        query = { penaltyUnitCalculatorQuery }
        loadingComponent = { PenaltyUnitsJurisdictionsSkeleton }
      >
        <PenaltyUnitsJurisdictionsComponent />
      </Apollo>
    </AdminLayout>
  );
};

export default WithPrivateRoute(ToolsPenaltyUnits);