import React from "react";
import Skeleton from 'react-loading-skeleton';

const PenaltyUnitsJurisdictionsSkeleton = () =>
{
  return (
    <div>
      <div className = "h-3 w-1/6 mb-4">
        <Skeleton className = "h-full" />
      </div>
      <div className = "h-16 w-full mb-8">
        <Skeleton className = "h-full" />
      </div>
      <div className = "flex flex-wrap">
        <div className = "h-40 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-40 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-40 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
          <Skeleton className = "h-full" />
        </div>
        <div className = "h-40 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
          <Skeleton className = "h-full" />
        </div>
      </div>
    </div>
  );
};

export default PenaltyUnitsJurisdictionsSkeleton;