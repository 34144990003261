import React from "react";
import Grid from "../grid";
import GoBack from "../goBack";
import { jurisdictionImages, defaultJurisdictionIllustration } from "../../utils/gridImages";

const PenaltyUnitsJurisdictionsComponent = (props) =>
{
  let jurisdictions = [...props.jurisdictionsQueryResults.data.allJurisdictions];

  for (let i = 0; i < props.jurisdictionsQueryResults.data.allJurisdictions.length; ++i)
  {
    jurisdictions[i].description = `Penalty Unit Legislations: ${jurisdictions[i].penaltyUnitMultipliers.length}`;
    jurisdictions[i].link = `/tools/penaltyunits/jurisdiction?j=${jurisdictions[i].id}`;
  }

  const addJurisdictionGridElement =
  {
    id: 999,
    name: "Add Jurisdiction",
    description: "Create a new Jurisdiction.",
    link: `/tools/penaltyunits/jurisdiction`
  };

  jurisdictions = [addJurisdictionGridElement].concat(jurisdictions);


  return (
    <section
      id = "utilitySection"
    >
      <GoBack
        text = "← Back to Tools Menu"
        destination = "/admin/tools"
      />
      <div className = "text-center">
        <h2 className="bg-yellow-400 text-2xl font-bold inline-block mb-8 p-3">
          Choose a Jurisdiction to update
        </h2>
        <Grid
          gridElements = { jurisdictions }
          elementImages = { jurisdictionImages }
          defaultElementImage = { defaultJurisdictionIllustration }
          isAdminPage = { true }
        />
      </div>
    </section>
  );
};

export default PenaltyUnitsJurisdictionsComponent;